export default {
  en: {
    common: {
      bottom_section_i_agree: "I agree",
      bottom_section_continue: "Continue",
      bottom_section_finish: "Finish",
      bottom_section_email_copy_label: "Email me a copy",
      bottom_section_back_label: "Back",
      already_have_account_label: "Already have an account?",
      sign_in_to_amway: "Sign In to Amway",
      first_things_first_label: "First things first.",
      why_are_we_asking_this: "Why are we asking this?",
      enter_your_email_id: "Enter your Email ID",
      enter_email_placeholder: "example@email.com",
      send_btn_label: "Send",
      spanish_label: "Español",
      english_label: "English",
      email_sent_label: "Email sent.",
      login_label: "Login",
      logout_label: "Logout",
      height_title_label: "Height",
      feet_unit_label: "ft",
      inch_unit_label: "in",
      feet_and_pound_unit_label: "FT / LBS",
      centimeter_and_kilogram_unit_label: "CM / KG",
      no_i_like_everything: "No, I like everything",
      my_results: "My Results",
      my_foods: "My Foods",
      my_products: "My Products",
      my_tips: "My Tips",
      all_foods: "All Foods",
      all_tips: "All Tips",
      all_products: "All Products",
      for_your: "For your",
      foods_to_feast: "Foods to Start With",
      best_product: "Top Product for You",
      best_products: "Top Products for You",
      tips_to_try: "Wellness Tips for You",
      view_all_foods: "Explore More Foods",
      view_all_products: "Explore More Products",
      view_all_tips: "Explore More Tips",
      where_to_start: "Where To Start",
      see_recipes: "See Why",
      see_why: "See Why",
      learn_more_home: "learn more",
      best_products_for_you: "Best Products For You",
      your_top_products: "Your top products",
      other_products: "Other Products",
      add_to_cart: "Add To Cart",
      filters: "Filters",
      wellness_areas: "Wellness Areas",
      link_copied: "This link has been copied to your clipboard!",
      user_added_product_to_cart: "Nice! Product added to cart.",
      user_deleted_product_from_cart: "This product has been removed.",
      dyk_text: "Did you know?",
      welcome_back: "Welcome back!",
      guest_expiry_message:
        "Oh no! We weren't able to save the data you provided. Sign in this time to save your progress.",
      sign_in: "Sign In",
      sign_out: "Sign Out",
      login_session_expiry_message:
        "Since you've been gone a while, we logged you out of the Wellness Recommender. Log back in to get going!",
      save_result_message:
        "Want to save your recommendations for next time? Go ahead and create an account. ",
      save_result: "Save your Results!",
      hold_on: "Hold on!",
      retake_assessment_message:
        "Are you sure you want to start over? You'll lose your previous results.",
      retake_assessment: "Retake Assessment",
      share_assessment: "Share Assessment",
      share_the_assessment: "Share the Assessment",
      go_to_my_result: "Show me my results",
      take_survey: "Take the Assessment",
      my_account: "My Account",
      i_like_everything: "I like everything!",
      no_allergies_for_me: "No allergies for me!",
      subtotal: "Subtotal",
      excluding_taxes_and_other_charges: "(Excluding taxes and other charges)",
      // amperks_points_earned: "AmPerks points earned",
      checkout: "Checkout",
      create_an_account: "Create an account",
      want_to: "Want to",
      before_you_get_started: "before you get started?",
      take_assessment: "Take Assessment",
      all_foods_to_eat: "All Foods to Eat",
      all_foods_to_skip: "All Foods to Limit",
      product_recommendation_message:
        "Sorry, we don't have any product recommendations for you here because of some of your responses. Please check out recommendations in your other wellness areas.",
      products_recommendation_message:
        "Sorry, we don't have any product recommendations for you here because of some of your responses. ",
      close: "Close",
      take_me_to_products: "Take me to products",
      my_cart: "My cart",
      items: "items",
      item: "item",
      what_to_eat: "Foods to eat",
      what_to_avoid: "Foods to limit",
      why: "Why",
      recipe: "Recipe",
      return_to_my_results: "Return to my results",
      dont_go: "Wait, don't go!",
      you_want_to_leave: "Are you sure you want to leave?",
      stay_here: "No, stay here",
      yes_leave: "Yes, leave",
      footer_statement:
        "†This statement has not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.",
      footer_copyright: "Copyright © 2021 Amway.",
      footer_privacy_notice: "Privacy Notice",
      footer_policy_security: "Policy & Security",
      footer_terms_of_use: "Terms of Use",
      ingredients: "Ingredients",
      instructions: "Instructions",
      faqs: "FAQs",
      serving: "serving",
      servings: "servings",
      product_details: "Product Details",
      delete_item_from_cart: "Delete item from cart",
      skip_to_take_survey: "Skip to take assessment",
      wellness_area: "Wellness Area",
      keep_going: "Let's keep going!",
      copy_link_text: "Copy",
      link_popup_title: "Shareable link",
      reviews: "Reviews",
      of: "of",
      tips: "Tips",
      foods: "Foods",
      products: "Products",
      start_the_assessment: "Start the Assessment",
      get_started: "Get Started",
      out_of_stock: "Temporarily Out-of-Stock",
      product_failed_add_to_cart: "Sorry, this product was not able to add to cart at this time",
      learn_more_product: "Learn More",
      skip_to_keep_going: "Skip to keep going",
      skip_to_my_results: "Skip to my results",
    },
    error: {
      please_enter_name: "Please enter a name to continue",
      please_enter_email: "Please enter an Email ID to continue",
      please_enter_valid_email: "Please enter valid Email ID to continue",
      we_would_like_to_know_this: "We'd like to know this",
      please_select_atleast_one_option: "Please select atleast one option",
      please_select_one_of_the_options: "Please select one of the options",
      add_recommendations: "Add recommendations to your cart!",
      connection_error: "Connection error! Try reconnecting.",
      error_button_try_again: "Try again",
      no_recipe_message_title: "No recipe needed!",
      no_recipe_message:
        "There are benefits in making this item a part of your regular diet, so add it to your grocery list this week and enjoy!",
      page_doesnt_exist: "Oops! That page doesn't exist.",
      got_to_home_page: "Go to the home page",
      product_not_available: "This product isn’t available",
      product_fallback_details:
        "Unfortunately this product hasn’t been launched yet, but it’s coming soon! Please check out your other product recommendations.",
      sku_id: "SKU ID",
      api_fail_error: "Oops! Let's get you back on track.",
    },
  },
};
